<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center"
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.stasionary_cashier") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end"
        >
          <el-button
            class="mr-1"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
            >{{ $t("message.create") }}</el-button
          >
          <el-button
            class="mr-2"
            size="mini"
            @click="fetchRep()"
            icon="el-icon-document-delete"
          >
            Excel
          </el-button>
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column, index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                  >{{ column.title }}</el-checkbox
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div
        class="col-7 align-items-center align-self-center text-left p-0 mb-2 m-d-flex w-reset"
      >
        <span class="align-items-center align-self-center pr-2"
          ><el-tag
            >{{ $t("message.total_amount") }}:
            {{ Math.round(balanceReport.total_amount) | formatMoney }}
          </el-tag></span
        >
        <span class="align-items-center align-self-center pr-2"
          ><el-tag type="success"
            >{{ $t("message.paid") }}:
            {{ Math.round(balanceReport.paid_up) | formatMoney }}
          </el-tag></span
        >
        <span class="align-items-center align-self-center pr-2"
          ><el-tag type="warning"
            >{{ $t("message.not_paid") }}:
            {{ Math.round(balanceReport.not_paid) | formatMoney }}
          </el-tag></span
        >
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <th v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.fullName.show">{{ columns.fullName.title }}</th>
            <th v-if="columns.summa.show">{{ columns.summa.title }}</th>
            <th v-if="columns.contractNumber.show">
              {{ columns.contractNumber.title }}
            </th>
            <th v-if="columns.typePatsent.show">
              {{ columns.typePatsent.title }}
            </th>
            <th v-if="columns.doctor.show">{{ columns.doctor.title }}</th>
            <th v-if="columns.data.show">{{ columns.data.title }}</th>
            <th v-if="columns.settings.show">{{ columns.settings.title }}</th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.fullName.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.fullName"
                :placeholder="columns.fullName.title"
              ></el-input>
            </th>
            <th v-if="columns.summa.show"></th>
            <th v-if="columns.contractNumber.show"></th>

            <th v-if="columns.typePatsent.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.typePatsent"
                :placeholder="columns.typePatsent.title"
              ></el-input>
            </th>
            <th v-if="columns.doctor.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.doctor"
                :placeholder="columns.doctor.title"
              ></el-input>
            </th>
            <th v-if="columns.data.show">
              <el-date-picker
                :placeholder="columns.data.title"
                v-model="filterForm.created_at"
                size="mini"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="user in list"
            :key="user.id"
            class="cursor-pointer"
            :style="
              user.total_amount == (user.paid_up ? user.paid_up : 0)
                ? 'background:#85A8FF;'
                : user.total_amount > (user.paid_up ? user.paid_up : 0) &&
                  (user.paid_up ? user.paid_up : 0) > 0
                ? 'background:#F9FF89;'
                : (user.paid_up ? user.paid_up : 0) == 0
                ? 'background:#FFFFFF;'
                : ''
            "
            id="tr_pointer"
          >
            <td v-if="columns.id.show">{{ user.id }}</td>
            <td v-if="columns.fullName.show">
              {{
                user.patient_history
                  ? user.patient_history.hospitalPatient
                    ? user.patient_history.hospitalPatient.first_name
                    : ""
                  : ""
              }}
              {{
                user.patient_history
                  ? user.patient_history.hospitalPatient
                    ? user.patient_history.hospitalPatient.surname
                    : ""
                  : ""
              }}
            </td>
            <td v-if="columns.summa.show">{{ user.total_amount }}</td>
            <td v-if="columns.contractNumber.show">{{ user.paid_up }}</td>
            <td v-if="columns.typePatsent.show">
              {{ user.patient_history ? user.patient_history.number : "" }}
            </td>
            <td v-if="columns.doctor.show">
              {{
                user.patient_history.doctor
                  ? user.patient_history.doctor.name
                  : ""
              }}
              {{
                user.patient_history.doctor
                  ? user.patient_history.doctor.surname
                  : ""
              }}
            </td>
            <td v-if="columns.data.show">{{ user.created_at }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  (user.patient_history.hospitalPatient
                    ? user.patient_history.hospitalPatient.first_name
                    : '') +
                  ' ' +
                  (user.patient_history.hospitalPatient
                    ? user.patient_history.hospitalPatient.surname
                    : '')
                "
                placement="left"
              >
                <el-button @click="pay(user.patient_history)" round>{{
                  $t("message.pay")
                }}</el-button></el-tooltip
              >
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="90%"
      :wrapperClosable="false"
      :visible.sync="drawer.create.status"
      :ref="drawer.create.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <crm-create
        :ref="drawer.create.component"
        :drawer-name="drawer.create.name"
      ></crm-create>
    </el-drawer>

    <el-drawer
      size="90%"
      :wrapperClosable="false"
      :visible.sync="drawer.createFrom.status"
      :ref="drawer.createFrom.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.createFrom.component)"
      @closed="drawerClosed(drawer.createFrom.component)"
    >
      <crm-create-from-id
        :selected="selectedModel"
        :parent-page="'cashierStationary'"
        :ref="drawer.createFrom.component"
        :drawer-name="drawer.createFrom.name"
      ></crm-create-from-id>
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import CrmCreateFromId from "./components/crm-create-from-id";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import { i18n } from "@/utils/i18n";

export default {
  mixins: [list, drawer],
  name: "cashierStationary",
  data() {
    return {
      selectedModel: {},
      drawer: {
        create: {
          name: "create",
          component: "componentCreate",
          status: false,
        },
        createFrom: {
          name: "createFrom",
          component: "componentCreateFrom",
          status: false,
        },
      },
      data_s: "",
      loadingData: false,
      tableID: false,
      tableUser: false,
    };
  },
  components: {
    CrmCreate,
    CrmCreateFromId,
  },
  computed: {
    ...mapGetters({
      list: "patientBalances/list",
      balanceReport: "patientBalances/balance_report",
      columns: "patientBalances/columns",
      pagination: "patientBalances/pagination",
      filter: "patientBalances/filter",
      sort: "patientBalances/sort",
      corpses: "patientBalances/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() {},
  methods: {
    ...mapActions({
      updateList: "patientBalances/index",
      getRep: "patientBalances/getRep",
      updateSort: "patientBalances/updateSort",
      updateFilter: "patientBalances/updateFilter",
      updateColumn: "patientBalances/updateColumn",
      updatePagination: "patientBalances/updatePagination",
      editModel: "patientBalances/show",
      empty: "patientBalances/empty",
      delete: "patientBalances/destroy",
      refreshData: "patientBalances/refreshData",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    pay(model) {
      this.selectedModel = model;
      this.drawer.createFrom.status = true;
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    fetchRep() {
      const query = {
        start_date: this.filterForm.start_date,
        end_date: this.filterForm.end_date,
      };
      this.getRep(query)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Отчет-Стационар-Касса.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
        });
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
    beforeClose(done) {
      this.empty();
      this.refreshData();
      done();
    },
    open() {
      this.$confirm(
        i18n.t("message.delete_notification"),
        i18n.t("message.warning"),
        {
          confirmButtonText: i18n.t("message.yes"),
          cancelButtonText: i18n.t("message.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: i18n.t("message.delete_success"),
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: i18n.t("message.delete_calcel"),
          });
        });
    },
  },
};
</script>

<style >
#tr_pointer {
  cursor: pointer;
}
</style>
